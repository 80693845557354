import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage12Lehte_ei_leitud from "./pages/Webpage12Lehte_ei_leitud";
import Webpage14Tingimused from "./pages/Webpage14Tingimused";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiN2RmZWI4MDc2NTkzMzMzMzllNGU1MmVhNDM4NGFiMDc2ZjEyZTU5ZGQwN2I5MjI4YWQyY2FlYTAxNWU0NTVkMWZhNjYwZWI5YzNmMGUzOTIiLCJpYXQiOjE3NDE3NjU2NDIuMTQwOTExLCJuYmYiOjE3NDE3NjU2NDIuMTQwOTMsImV4cCI6MTc0MTg1MjA0Mi4xMzc3MzksInN1YiI6IjYzIiwic2NvcGVzIjpbXX0.RAaZ5IlyW_3Vz7eXRkWIO53ioJ06H1351Vwdxr_W_BH11nENyVROHG-PRvjF28SSo2sgsitxyRp5MdmHFqXFBvhs0CNoe1iO4d3gDkp1yGCWOH7Fx91UWbEm0dRKMRAszCV-eNFZHolh-l1kXaeMqHaeTrooHpUqGyTF3ORS8eqNitrA0kHqf83Uqg7aywJXjOvnnJBaMjvvzAC5SyYbMq7xTbM11CxpJPCzvsJoBYXGcaaCqOx3SM-ZaK2U5jsrO0UXlkxUVwHEDuxDB7j-THFO4yN2-rb0afljPINFBWT62PoICk9SaI7Bc_3qd5wT8UjHn0FpMl4KNQhvqPXV2z5z8sPtu9vBQTsWjM7TMfFWnMZAefCsQabyDOpPFupPAZrSYLSbD4Fo3-EzLaZC_TUAyb5FyIOLytK1wCKVTiCwR2sRAdn7IdZXuR7dlw5ajocZ-9n55ooKzQaR_c1w24EKas93kHBkYbdFXtHGpnFrKCcGjgKBOdxds86POYuXIQX4Ll15HGjYYY0kujty_9PVUqf-nIC2oBofaRu8IIVwK2kXDG7VKbd96ZMmmt8qR1pnKHtPeSyfe7FLeNESux1mrMpt-CbgyAvRBYLBCO8rR_slzdTGgz1nac17FEL2ijoN_gZmKqZHkZnfWx0uYn1JNcRWS8Zy-vrRLZFIp6E',
    'Access-api-key': "eyJpdiI6Im9pWjJKK1U1RWR4dGxGNGtRN2JQc1E9PSIsInZhbHVlIjoiRmFoUWsreDN4WTlZRkpsb20valordz09IiwibWFjIjoiNDg5NmI5NzYzYTk4OTliMDJhODM4MTVjN2I1Zjg4NGY5YzUxY2M5ZmVlYzQ2ZDc5NjliZjU4YzQzNzRkN2QxMSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6InYwRkNIdnRjSEluVnFqRWpRNzdGblE9PSIsInZhbHVlIjoidC9PbHhadUR0akRobWx6QTJDNmtSdz09IiwibWFjIjoiZWIzZTYzOGNiOTQ4MzNkYWYzNjFjMzYxMTcyOGYwOTAzODllNGQ3NjIwZjMyZTA5MDE2ZjBjOGMzNGYwYWE2OSIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-KHPWXL9"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                <Route path="*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage14Tingimused />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}